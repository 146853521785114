<template>
  <ctk-aside-dialog
    v-model="modalState"
    :title="$t('offers.titles.your_booking')"
    modal-class="dialog-booking"
    :only-aside="!summaryOptionsNeeded.length"
  >
    <template #aside>
      <div class="tw-flex tw-flex-col">
        <offer-summary-card
          :title="$t('app.labels.offer')"
          :offer="getCurrentOffer"
          class="tw-mb-5"
          data-test="summary"
        />

        <ReassuranceSection
          :icon="require('@/assets/img/icons/quotation/contract.svg')"
          :title="$t('app.labels.engagement')"
          :content="$t('offers.paragraphs.booking.engagement')"
          data-test="reassurance"
        />

        <div
          v-if="($wait.is('booking offer') && !summaryOptionsNeeded.length)"
          class="load-layer tw-rounded"
        >
          <ui-loader
            :size="40"
            background-back="#000"
            type="pallet"
          />
        </div>

        <ui-button
          v-if="!summaryOptionsNeeded.length"
          class="tw-px-4 tw-w-full tw-self-center md:tw-w-auto tw-mt-6"
          variant="primary"
          @click="bookOffer"
        >
          <i18n
            v-if="price"
            path="booking_offer"
          >
            <template
              #amount
            >
              <strong>
                {{ price | currency(getCurrentOffer.pricing.currency, $i18n.locale) }}
              </strong>
            </template>
          </i18n>
          <span
            v-else
          >
            {{ $t('booking_offer_title') }}
          </span>
        </ui-button>
      </div>
    </template>

    <div
      v-if="summaryOptionsNeeded.length"
      class="tw-relative tw-flex tw-flex-col tw-p-4 md:tw-px-10 md:tw-pb-10 md:tw-pt-28 tw-h-full"
    >
      <div
        v-if="$wait.is('booking offer')"
        class="load-layer"
      >
        <ui-loader
          :size="40"
          background-back="#000"
          type="pallet"
        />
      </div>

      <summary-offer
        :offer="getCurrentOffer"
        :content="$t('offer.booking_dialog.summary_offer.content')"
        @validate="bookOffer"
        @back="closeDialog"
      >
        <template
          v-if="price"
          #text-button
        >
          <i18n
            path="booking_offer"
          >
            <template
              #amount
            >
              <strong>
                {{ price | currency(getCurrentOffer.pricing.currency, $i18n.locale) }}
              </strong>
            </template>
          </i18n>
        </template>
        <span
          v-else
        >
          {{ $t('booking_offer_title') }}
        </span>
      </summary-offer>
    </div>
  </ctk-aside-dialog>
</template>

<script>
  import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'

  import CtkAsideDialog from '@/components/CtkAsideDialog/index.vue'
  import SummaryOffer from './_subs/SummaryOffer/index.vue'
  import OfferSummaryCard from './_subs/OfferSummaryCard/index.vue'
  import ReassuranceSection from '@/views/Carriers/Offers/components/DialogBooking/_subs/ReassuranceSection'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useStore from '@/composables/useStore'
  import useMatomo from '@/composables/useMatomo'
  import useWait from '@/composables/useWait'
  import useRouter from '@/composables/useRouter'
  import useRoute from '@/composables/useRoute'

  import { OfferBooking } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import Hotjar from '@/plugins/VueHotjar'
  import { EventBus } from '@/services/EventBus'
  import { i18n } from '@/locales'

  export default defineComponent({
    name: 'DialogBooking',
    components: {
      CtkAsideDialog,
      SummaryOffer,
      OfferSummaryCard,
      ReassuranceSection
    },
    emits: [
      'value',
      'offer-booked'
    ],
    model: {
      prop: 'value'
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props, { emit }) {
      const { state: modalState } = useModelGetterSetter(props, 'value')
      const store = useStore()
      const matomo = useMatomo()
      const wait = useWait()
      const router = useRouter()
      const route = useRoute()

      const errorMessage = ref(null)
      /** @type {import('@vue/composition-api').Ref<any>} */
      const updateInterval = ref(null)

      /**
       * @type {import('@vue/composition-api').Ref<Array<string>>}
       **/
      const summaryOptionsNeeded = ref([])

      /**
       * @type {import('@vue/composition-api').Ref<number|null>} price
       */
      const price = computed(() => {
        const offer = getCurrentOffer.value

        return offer && offer.pricing
          ? offer.pricing.price
          : null
      })

      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])

      function closeDialog () {
        modalState.value = false
      }

      /**
       * @function getOptionsNeeded
       * @returns {Array<string>}
       */
      function getOptionsNeeded () {
        /**
         * @function showSummaryDirection
         * @param {{ [key: string]: any}} offer
         * @param {string} direction
         */
        const showSummaryDirection = (offer, direction) => {
          const { address, handling } = offer[direction]
          const { driver, tail_lift: tailLift } = handling

          const vehicleTypeIssue = ['lightweight_vehicle', 'carrier_truck']
          const hasAccessibilityIssue = address && !!address.accessibility &&
            !!address.accessibility.vehicle_type &&
            vehicleTypeIssue.includes(address.accessibility.vehicle_type.key)

          return (driver || tailLift || !!offer[direction].comment || hasAccessibilityIssue)
        }

        /**
         * @function showSummaryGoods
         * @param {{ [key: string]: any }} offer
         * @returns {boolean}
         */
        const showSummaryGoods = (offer) => {
          return !!offer.load.description
        }

        return [
          ...(showSummaryDirection(getCurrentOffer.value, 'pickup') ? ['pickup'] : []),
          ...(showSummaryDirection(getCurrentOffer.value, 'delivery') ? ['delivery'] : []),
          ...(showSummaryGoods(getCurrentOffer.value) ? ['goods'] : [])
        ]
      }

      /**
       * @function offerBooked
       * @param {string} missionUuid
       * @returns {void}
       */
      function offerBooked (missionUuid) {
        closeDialog()
        emit('offer-booked', missionUuid)

        Hotjar.tag('Active Carrier')
        Hotjar.event('offer_booked')

        /**
         * Keep the redirection only for users on the
         * older view
         */

        /**
         * If the user is on the new offer interface, then do not
         * redirect it automatically to the list once the offer is
         * booked.
         * TODO: Remove the whole behaviour when we remove the old offer view.
         */

        const isOffer = route.value.name === 'Offer'
        if (isOffer) {
          router.value.push({
            name: 'Offers'
          })
            .catch(() => {})
        }

        store.value.dispatch('offers/getOffers', { pageNumber: null })
          .catch(() => {})

        store.value.dispatch('offers/resetCurrentOffer')

        store.value.dispatch('offers/refreshSearchesCount')
        EventBus.$emit('refresh-proposal-section')
      }

      /**
       * TODO: Possible issue here with the getCurrentOffer.
       * The uuid of the offer to be booked should be passed in the arguments.
       * Currently we suppose the getCurrentOffer exists & is the right one.
       * @function bookOffer
       * @returns {Promise<unknown>}
       */
      function bookOffer () {
        const currentOffer = getCurrentOffer.value

        if (matomo) {
          matomo.trackEvent('Offers', 'Confirmed Booking', currentOffer.uuid)
        }

        wait.start('booking offer')
        return OfferBooking.save({
          cid: store.value.getters['auth/getCid'],
          oid: currentOffer.uuid
        }, null, {})
          .then(({ data }) => {
            offerBooked(data.uuid)
          })
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              if (err.response.status === 404) {
                closeDialog()
                showToaster(null, i18n.t('offers.paragraphs.not_found'), {
                  type: 'error',
                  position: 'bottom-right'
                })

                return
              }

              if (err.response.status === 400) {
                closeDialog()
                showToaster(null, i18n.t('an_error_has_occurred_on_booking'), {
                  type: 'error',
                  position: 'bottom-right'
                })

                return
              }

              /**
               * Show the violation message in the input
               */
              if (data.error.violations) {
                // @ts-ignore
                data.error.violations.map((violation) => {
                  switch (violation.property_path) {
                  case 'phone':
                    errorMessage.value = violation.message
                    break
                  }
                })
              }

              const errMessage = data.error.detail || data.error.title
              showToaster(null, errMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            wait.end('booking offer')
          })
      }

      function initializeDialog () {
        summaryOptionsNeeded.value = getOptionsNeeded()
      }

      onMounted(() => {
        initializeDialog()
      })

      watch(modalState, v => {
        if (v) {
          initializeDialog()
        } else {
          summaryOptionsNeeded.value = []
        }
      })

      onUnmounted(() => {
        if (updateInterval.value) clearInterval(updateInterval.value)
      })

      return {
        price,
        getCurrentOffer,
        summaryOptionsNeeded,
        closeDialog,
        bookOffer,
        modalState,
        errorMessage,
        getOptionsNeeded
      }
    }
  })
</script>

<style lang="scss">
.dialog-booking .modal-container {
  height: clamp(300px, 743px, 100%);
}
.dialog-booking .ctk-aside-dialog__main, .dialog-booking .ctk-aside-dialog__body, .dialog-booking .modal-body {
  height: 100%;
}
.dialog-booking .summary-offer .ctk-address-infos__title__content, .dialog-booking .summary-offer .ctk-goods-infos__title__content {
  font-weight: 500;
  font-size: 0.875rem;
}
.dialog-booking .summary-offer .ctk-address-infos__title__icon, .dialog-booking .summary-offer .ctk-goods-infos__title__icon {
  font-size: 1.875rem;
}
@media (min-width: 770px) {
  .dialog-booking .summary-offer__footer {
    position: relative;
  }
  .dialog-booking .summary-offer__footer::before {
    position: absolute;
    content: '';
    left: -2.5rem;
    top: 0;
    width: calc(100% + 5rem);
    height: 10px;
    box-shadow: 0 -2px 2px 0 rgba($secondary-text, 0.25);
  }
}
</style>
